import type { QueryConfig } from '@/core/features/a-component/hooks/use-component-query';
import type { PageComponent } from '@/core/features/a-dynamic-page/dynamic-page-pacts/dynamic-page-type';
import type { UserQuery } from '@/core/features/a-dynamic-page/dynamic-page-types';

import React from 'react';

import useComponentQuery from '@/core/features/a-component/hooks/use-component-query';
import { logger } from '@/core/features/logger/logger';
import { is404 } from '@/core/features/request/client-side-request';

type ComponentResponse<Component> = {
    data: Component | null;
    isError: boolean;
    isLoading: boolean;
};

type OptionsType<Component> = {
    addedUserQuery?: UserQuery;
    config?: QueryConfig<Component>;
    is404Accpeted?: boolean;
    queryName: string;
};

export default function useComponentDataByIri<Component extends PageComponent>(
    componentIri: Component['@id'] | null,
    options: OptionsType<Component>,
): ComponentResponse<Component> {
    const [componentData, setComponentData] = React.useState<Component | null>(options.config?.initialData ?? null);
    const { data, error, isError, isLoading, isSuccess } = useComponentQuery<Component>(componentIri, options);

    React.useEffect(() => {
        if (!componentIri) {
            setComponentData(null);
        }
    }, [componentIri]);

    React.useEffect(() => {
        if (isSuccess) {
            setComponentData(data);
        }
    }, [data, isSuccess]);

    if (error && (!options.is404Accpeted || !is404(error))) {
        logger.error('Error in useComponentDataByIri: Could not load component', {
            additionalData: JSON.stringify({ error, options }),
            url: componentIri ?? undefined,
        });
    }

    return {
        data: data || componentData,
        isError,
        isLoading: isLoading && !!componentIri,
    };
}
